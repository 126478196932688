import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import BannerLineas from "../components/BannerLineas";
import CABL from "../assets/images/fama/CABL.jpg";
import EA from "../assets/images/fama/EA.jpg";
import GIAC from "../assets/images/fama/GIAC.jpg";
import GPE from "../assets/images/fama/GPE.jpg";
import SHI from "../assets/images/fama/SHI.jpg";

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Somos Famosos - Plasticos Lucero</title>
      <meta
        name="description"
        content="Algunos de nuestros productos aparecieron en distintos programas de televisión como tambien en comerciales"
      />
    </Helmet>

    <BannerLineas
      title="Somos Famosos!"
      subTitle="Algunos de nuestros productos aparecieron en distintos programas de televisión como tambien en comerciales"
      image={EA}
    />

    <div id="main">
      <section id="two" className="spotlights">
        <section>
          <div className="image">
            <img src={SHI} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sin hijos (película 2015)</h3>
              </header>
              <p>
                Sin hijos es una película argentina de comedia y romance de 2015 dirigida por Ariel Winograd y
                protagonizada por Diego Peretti y Maribel Verdú. Se puede ver como aparece el{" "}
                <b>VASO SORBITO CUCURUCHO (Art. 300 - Art. 340)</b>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={GPE} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Un gallo para Esculapio (serie 2017)</h3>
              </header>
              <p>
                Un gallo para Esculapio es una serie de televisión dramática argentina emitida por Telefe y producida
                por Underground. Es protagonizada por Luis Brandoni, Peter Lanzani, Luis Luque, Julieta Ortega, Ariel
                Staltari y Eleonora Wexler. Se puede ver como aparece el{" "}
                <b>VASO SORBITO CUCURUCHO (Art. 300 - Art. 340) y VASO SORBITO BOTA CON SOMBRERO (Art. 320)</b>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={GIAC} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Giacomo Capelettini (comercial 2012)</h3>
              </header>
              <p>
                Se puede ver como aparece el <b>VASO SORBITO BOTA CON SOMBRERO (Art. 320)</b>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={CABL} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Cablevision (comercial 2012)</h3>
              </header>
              <p>
                Comercial de la agencia Don para Cablevision y su producto On Demand "Ellos tienen Cablevision HD y por
                eso ondemandean a lo loco". Se puede ver como aparece el{" "}
                <b>VASO SORBITO CUCURUCHO (Art. 300 - Art. 340)</b>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={EA} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Eameo</h3>
              </header>
              <p>
                La famosa cuenta "Eameo" conocida por sus creatividad en la creacion de Memes realizó uno con la imagen
                de la famosa pelicula "Pulp Fiction" donde se ve a John Travolta y Samuel Jackson sostiendo los
                productos <b>VASO SORBITO CUCURUCHO (Art. 300 - Art. 340) y VASO SORBITO REVOLVER (Art. 260)</b>
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default Landing;
